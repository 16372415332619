import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-message',
  template: ` 
  <div align="center">
    <h4> Welcome to the new Nifsoft Classroom portal </h4> 
    <h6>Have a great learning Experience !!!</h6>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
  </mat-dialog-actions>`,
  styles: []
})
export class AlertMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
