import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';
import { MsContentService } from '../ms-content.service';

@Component({
  selector: 'app-content-items',
  templateUrl: './content-items.component.html',
  styleUrls: ['./content-items.component.css']
})
export class ContentItemsComponent implements OnInit {

  currentUser: any;
  school_id: number;
  class_id: number;
  lesson_id: number;
  contentPath: string;
  contentZipPath: string;
  lessonName: string;
  className: string;
  country_id: number;
  user_id: number;
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  appContentUrl = environment.appContentUrl;
  contentUrl = environment.contentUrl;
  openHtmlContentPath = '';
  country_lesson_id: number;
  type_order: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: MsContentService,
    private _backendService: BackendService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser){
      this.user_id = this.currentUser.id;
      this.school_id = this.currentUser.currentSelSchoolId;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }
    this._backendService.getSchoolById({school_id:this.school_id}).subscribe((schoolData: any)=> {
      // console.log(schoolData);
      this.country_id = schoolData.data.schoolCountry.id;
      if(this.country_id == 2){
        this.contentService.getMapCountryLessons({lesson_id: this.lesson_id}).subscribe((data: any)=> {
          this.country_lesson_id = data.data.country_lesson_id;
          // console.log(this.country_lesson_id);
        })
      }
    })
    this.route.params.subscribe(params => {
      this.class_id = parseInt(params.class_id);
      this.lesson_id = parseInt(params.lesson_id);
    });
    this._backendService.getLesson(this.lesson_id).subscribe((lessonName: any)=> {
      // console.log(lessonName);
      this.lessonName = lessonName.data.lesson_name;
    });
    this._backendService.getClass(this.class_id).subscribe((className: any)=> {
      this.className = className.data.class_name;
    })

    let obj = {user_id: this.user_id}
    this._backendService.userSchools(obj).subscribe((data: any) => {
      // console.log(data);
      data.data.forEach(element => { this.schoolArr.push(element.id) });
      this.classArr = data.userClass;
      this.sectionArr = data.userSection;
    });

    this.chkMarksharksContent();
  }

  openContentData(){
    let obj = {
      country_id: this.country_id,
      lesson_id: this.lesson_id,
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr
    }
    this.contentService.getLessonContent(obj).subscribe((contentData: any) => {
      // console.log(contentData);
      if(contentData.data.length ==1 && contentData.data[0].content_type == 6){
        window.open(contentData.data[0].path);
      }else if(contentData.data.length ==1 && contentData.data[0].content_type == 1){
        this.openHtmlContentPath = this.contentUrl + '/' + contentData.data[0].path;
      }else if(contentData.data.length ==1 && contentData.data[0].content_type != 6){
        this.router.navigate(['/content-data/',this.class_id,contentData.data[0].id, this.lesson_id])
      }
      else if(contentData.data.length >1){
        this.router.navigate(['/content-topic/',this.class_id,this.lesson_id])
      }else if(contentData.data.length==0){
        this.openSnackBar("Content Under Construction", 'Close');
      }
    });
  }

  chkMarksharksContent(){
    let obj = {
      country_id: this.country_id,
      lesson_id: this.lesson_id
    }
    this.contentService.getAppLessonContent(obj).subscribe((appContent: any)=> {
      // console.log(appContent);
      if(appContent.data && appContent.data.path && appContent.data.zipPath){
        this.contentPath = appContent.data.path; //this.appContentUrl + '/' + appContent.data.path;
      } else {
        this.contentPath = '';
      }
    })
  }

  openAppContentData(){
    let obj = {
      country_id: this.country_id,
      lesson_id: this.lesson_id
    }
    this.contentService.getAppLessonContent(obj).subscribe((appContent: any)=> {
      // console.log(appContent);
      if(appContent.data){
        this.router.navigate(['/content-data/',this.class_id,appContent.data.id, this.lesson_id], {
          queryParams: { appContent: true}
        })
      }else{
        this.openSnackBar("Content Not Available", 'Close');
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  openTextbookContent(){
    let obj = {
      country_id: this.country_id,
      lesson_id: this.lesson_id,
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr
    }
    // console.log(obj);
    this.contentService.getTextbookLessonContent(obj).subscribe((contentData: any) => {
      // console.log(contentData);
      if(contentData.data.length ==1){
        this.router.navigate(['/content-textbook/',this.class_id,contentData.data[0].id, this.lesson_id])
      }
      else if(contentData.data.length >1){
        this.router.navigate(['/content-topic/',this.class_id,this.lesson_id], {
          queryParams: { contentType: 'textbook' }
        })
      }else if(contentData.data.length==0){
        this.openSnackBar("Content Under Construction", 'Close');
      }
    });
  }

}
