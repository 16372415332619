/**
 * Angular  decorators and services
 */
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  // ViewEncapsulation,
  DoCheck,
  AfterViewChecked,
  ViewChild
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AuthenticationService } from '../_services';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { from, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatSidenav } from '@angular/material';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';

/**
 * App Component
 * Entry Component
 */
@Component({
  selector: 'root',
  templateUrl: './root.component.html',
  styleUrls: [
    './root.component.css'
  ]
})
export class RootComponent implements OnInit, AfterViewChecked {

  public title = 'Nifsoft Classroom';

  @ViewChild('sidenav') sidenav: MatSidenav;
  currentUser: any;
  isMobile = false;
  username: string;
  userType: string;
  showMenuItem = false;
  liveUrl = environment.liveUrl;
  currPage: string;
  signupPage: string;
  teacherSignUp: string;
  verification: string;
  mode = "side";
  currentUrl: string;
  allowedUrls = [];
  menuItems = [];
  opened = true;
  schoolVal = [];
  publish_status: number;
  subscription: Subscription;
  sideNavCloseVal = true;

  constructor(
    // public appState: AppState,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthenticationService,
    breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        // this.activateHandsetLayout();
        this.isMobile = true;
        this.mode = 'over';
      }
    });

    router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        this.currentUrl = event.url;
        if(event.url=='/dashboard' && !this.isMobile){
          this.mode = 'side';
          this.opened = true;
        } else {
          this.mode = 'over';
          this.opened = false;
        }
        
        if(event.url.includes('download-report')){
          this.currPage = 'download-report';
        }
        else if(event.url.includes('user-verification')){
          this.currPage = 'user-verification';
        }
        else if(event.url.includes('signup')){
          this.currPage = 'signup';
        }
        else if(event.url.includes('teacher')){
          this.currPage = 'teacher';
        }else if(event.url.includes('classCode')){
          this.currPage = 'classCode';
        }else if(event.url.includes('forbidden')){
          this.currPage = 'forbidden';
        }else if(event.url.includes('forgotPassword')){
          this.currPage = 'forgotPassword';
        }else if(event.url.includes('updatePassword')){
          this.currPage = 'updatePassword';
        }
        else{this.currPage = null}
        // if(event.url.includes('verification')){
        //   this.currPage = 'verification';
        // }
        // else{
        //   this.verification = null;
        //   this.signupPage = null;
        //   this.teacherSignUp = null;
        // }

      }
            
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }

  ngAfterViewChecked(): void {    
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser){
      this.username = this.currentUser.userData[0].studentName;
      this.userType = this.currentUser.userData[0].user_type;
     // this.schoolVal = this.currentUser.userSchools.filter(data => data.id == this.currentUser.currentSelSchoolId);
     if (Array.isArray(this.currentUser.currentSelSchoolId) && this.currentUser.currentSelSchoolId.length > 0) {
      this.schoolVal = this.currentUser.userSchools.filter(data => data.id === this.currentUser.currentSelSchoolId[0]);
      if(this.schoolVal.length === 0 ){
        this.schoolVal.push(this.currentUser.userSchools[0]);
      }
    } else if (this.currentUser.currentSelSchoolId.length === 0) {
      this.schoolVal.push(this.currentUser.userSchools[0]);

    } else {
      this.schoolVal = this.currentUser.userSchools.filter(data => data.id === this.currentUser.currentSelSchoolId);
    } 
    if (this.currentUser.adminUser[0].user_Type.type_order == '1' && this.schoolVal.length == 0) {
      this.schoolVal = this.currentUser.activeSchools.filter(data => data.id === this.currentUser.currentSelSchoolId);    
    }
    this.publish_status = this.schoolVal[0].publish_status;

    } 
  }

  public ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.subscription = this.authService.getCurrUserDetails().subscribe(userData => {
      this.currentUser = userData;
      if(userData){
        this.currentUser = userData;
      }         
    });
    if(this.currentUser){
      this.username = this.currentUser.userData?this.currentUser.userData[0].studentName:'';
      this.userType = this.currentUser.userData[0].user_type; 
     // this.schoolVal = this.currentUser.userSchools.filter(data => data.id == this.currentUser.currentSelSchoolId);
     if (Array.isArray(this.currentUser.currentSelSchoolId) && this.currentUser.currentSelSchoolId.length > 0) {
      this.schoolVal = this.currentUser.userSchools.filter(data => data.id === this.currentUser.currentSelSchoolId[0]);
      //console.log(this.schoolVal)
      if(this.schoolVal.length === 0 ){
        this.schoolVal.push(this.currentUser.userSchools[0]);
      }
    } else if (this.currentUser.currentSelSchoolId.length === 0) {
      this.schoolVal.push(this.currentUser.userSchools[0]);

    } else {
      this.schoolVal = this.currentUser.userSchools.filter(data => data.id === this.currentUser.currentSelSchoolId);
    }
    if (this.currentUser.adminUser[0].user_Type.type_order == '1' && this.schoolVal.length == 0) {
      this.schoolVal = this.currentUser.activeSchools.filter(data => data.id === this.currentUser.currentSelSchoolId);    
    }
    this.publish_status = this.schoolVal[0].publish_status;

    }    
    
    this.setAllowedUrls();
    // console.log("currentUrl",this.currentUrl)
    if(!this.authService.isAuthenticated() && this.currentUrl != '/login'){
      if(this.currentUrl == undefined){
        
      } else {
        this.router.navigate(['login']);
      }      
    }
    if(this.authService.isAuthenticated() && this.currentUrl == '/login'){
      this.router.navigate(['dashboard']);
    }
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('menus');
    localStorage.removeItem('allowed');
    localStorage.removeItem('appitems');
    // this.close();
    this.router.navigate(['login']);
  }

  isAuth(isAuth: boolean) {
    if (isAuth) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
  }

  onRightClick() {
    alert('Right click is not allowed!');
    return false;
  }

  close() {
    //console.log("side",this.sideNavCloseVal)
    if(this.currentUrl != '/dashboard' && this.sideNavCloseVal){
      this.sidenav.close();
    }   
  }

  // profile(){
  //   this.router.navigate(['user']);
  // }

  setAllowedUrls(){
    let params = {};
    this.authService.getAllowedLinks(params).subscribe((navData: any) => {
      if(navData.status) {
        // console.log(navData)
        this.allowedUrls = navData.data;
        localStorage.setItem('allowed', JSON.stringify(this.allowedUrls));
      }      
    });
  }

  chkPermissions(){
    let allowStatus = [];
    this.menuItems = JSON.parse(localStorage.getItem('menus')); //All user assigned permissions
    if(this.menuItems){
      allowStatus = this.menuItems.filter(data => '/'+data.link == this.currentUrl);
    }

    this.allowedUrls = JSON.parse(localStorage.getItem('allowed')); //All common urls
    let allowedUrl = [];
    if(this.allowedUrls){
      allowedUrl = this.allowedUrls.filter(data => '/'+data.link == this.currentUrl);
    }
      
    if(allowStatus.length<1 && this.currentUrl != '/' && allowedUrl.length<1){
      this.router.navigate(['/forbidden']);
    }
  }

  getSideNavCloseVal(sideCloseVal){
    if(sideCloseVal == false){
      this.sideNavCloseVal = false;
    } else {
      this.sideNavCloseVal = true;
    }
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}

