import { RootComponent } from './root.component';
// import { HomeComponent } from './home.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { OnlineClassComponent } from '../online-class/online-class.component';
import { AttendanceComponent } from '../attendance/attendance.component';
import { StuAttendenceDetailComponent } from '../attendance/stu-attendance-detail/stu-attendance-detail.component';
import { AttendanceDetailComponent } from '../attendance/attendance-detail/attendance-detail.component';
import { QuizListComponent } from '../student-quiz-set/quiz-list/quiz-list.component';
import { StudentQuizSetComponent } from '../student-quiz-set/student-quiz-set.component';
import { AttemptedQuizListComponent } from '../student-quiz-set/attempted-quiz-list/attempted-quiz-list.component';
import { StudentAttemptedQuizHistoryComponent } from '../student-quiz-set/attempted-quiz-list/attempted-quiz-history/attempted-quiz-history.component';
import { AttemptQuizComponent } from '../student-quiz-set/attempt-quiz/attempt-quiz.component';
import { StartQuizComponent } from '../student-quiz-set/attempt-quiz/start-quiz/start-quiz.component';
import { PollQueAnsComponent } from '../poll-que-ans/poll-que-ans.component';
import { StartquizGuard } from '../_services/startquiz.guard'
import { TeacherAttendanceComponent } from '../attendance/teacher-attendance/teacher-attendance.component';
import { MsUserComponent } from '../ms-user/ms-user.component';
import { LeaderBoardComponent } from '../student-quiz-set/leader-board/leader-board.component';
import { UserInviteComponent } from '../ms-user/user-invite/user-invite.component';
import { OnlineSubjectsComponent } from '../online-class/online-subjects/online-subjects.component';
import { ForbiddenComponent } from '../forbidden/forbidden.component';
import { AuthGuard } from '../_guard';
import { PaymentsComponent } from '../payments/payments.component';
// import { AuthGuard } from '../_guard';
// import { NotFoundPageComponent } from '../notfoundpage';

export const routes = [
  {
    path: '',

    children: [
      {
        path: '',
        redicrectTo: '/dashboard',
        component: DashboardComponent,
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'online-class',
        component: OnlineClassComponent, 
        canActivate:[AuthGuard]
      },
      {
        path: 'online-class-page',
        component: OnlineSubjectsComponent, 
        canActivate:[AuthGuard]
      },
      {
        path: 'view-attendance',
        component: AttendanceComponent, 
        canActivate:[AuthGuard]
      },
      {
        path: 'studentAttendanceDetail/:id',
        component: StuAttendenceDetailComponent
      },
      {
        path: 'attendanceDetail/:id',
        component: AttendanceDetailComponent
      },
      {
        path: 'attendanceDetail/:id/:schedule_id',
        component: AttendanceDetailComponent
      },
      {
        path: 'quiz-items',
        component: StudentQuizSetComponent
      },
      {
        path: 'quiz-list',
        component: QuizListComponent, 
        canActivate:[AuthGuard]
      },
      {
        path: 'quizListAttempted',
        component: AttemptedQuizListComponent, 
        canActivate:[AuthGuard]
      },
      {
        path: 'leaderBoard/:quiz_id',
        component: LeaderBoardComponent, 
        canActivate:[AuthGuard]
      },
      {
        path: 'attemptedQuiz/:id/:set_id',
        component: StudentAttemptedQuizHistoryComponent, 
        canActivate:[AuthGuard]
      },
      {
        path: 'attempt-quiz/:id',
        component: AttemptQuizComponent
      },
      {
        path: 'start-quiz/:quiz_set_id/:id',
        component: StartQuizComponent, 
        canDeactivate: [StartquizGuard]
      },
      {
        path: 'pollQuestions',
        component: PollQueAnsComponent, 
        canActivate:[AuthGuard]
      },
      {
        path: 'teacherAttendance/:schedule_id',
        component: TeacherAttendanceComponent, 
        canActivate:[AuthGuard]
      },
      {
        path: 'ms-user',
        component: MsUserComponent
      },
      { path: 'invite', 
        component: UserInviteComponent, 
        canActivate:[AuthGuard]
      },
      { path: 'forbidden', 
        component: ForbiddenComponent
      },
      {
        path: 'payment',
        component: PaymentsComponent
      }
    ]
  }
];
