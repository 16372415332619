import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { WindowRefService } from 'src/app/_services/window-ref.service';
import { environment } from 'src/environments/environment';
import { SubscriptionService } from '../../subscription.service';

@Component({
  selector: 'app-razorpay',
  templateUrl: './razorpay.component.html',
  styleUrls: ['./razorpay.component.css']
})
export class RazorpayComponent implements OnInit {

  @Input() Subscription;
  currentUser: any;
  user_id: number;
  razorKey = environment.razorPayKey;
  redirectUrl = environment.liveUrl;
  schoolCode: string;
  quiz_user_id: number;
  redirect = '';
  firstName: string = '';
  email: string = '';

  constructor(public subscriptionService: SubscriptionService,
    private winRef: WindowRefService,
    private router: Router,
    private zone: NgZone) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.firstName = this.currentUser.adminUser[0].firstName;
    this.email = this.currentUser.adminUser[0].email;
    this.user_id = this.currentUser.adminUser[0].id;
    this.quiz_user_id = this.currentUser.id;
    this.schoolCode = this.currentUser.userSchools[0].school_code;
  }

  payWithRazor(){ 
     //console.log(this.Subscription)
    let options:any = {
        "key": this.razorKey,
        "amount": (this.Subscription.total_amount * 100),
        "name": "Nifsoft",
        "description": "Nifsoft Classroom",
        "image": "./assets/images/company_logo.png",
        "modal": {
          "escape": true
        },
        "prefill": {
          // "contact": this.currentUser.adminUser[0].contactNumber,
          // "email": this.currentUser.adminUser[0].email,
          // "method": 'card',
        //   'card[number]': this.finalObj.cardDetail.cardNumber,
        //   'card[expiry]': this.finalObj.cardDetail.cardExpDate,
        //   'card[cvv]': this.finalObj.cardDetail.cardCvv
        },
        "notes": {
          // "address": this.finalObj.addressDetail.address + ', ' + this.finalObj.addressDetail.landmark + ', ' + this.finalObj.addressDetail.city + ', ' + this.finalObj.addressDetail.state + '-' + this.finalObj.addressDetail.pincode
        },
        "theme": {
          "color": "#6fbc29"
        }
      };
      options.handler = ((response) => {
          // options['payment_response_id'] = response.razorpay_payment_id;
          this.createSubscription(response.razorpay_payment_id);
          
          // this.paymentService.payWithRazor({cart: this.finalObj, payment: options});
      });
      options.modal.ondismiss = (() => {
          // this.loginService.SetLoader = false;
          // console.log("dismiss");
          // this.createSubscription("123");
      });
      let rzp = new this.winRef.nativeWindow.Razorpay(options);
      rzp.open();
  }

  createSubscription(razorpay_payment_id){
    let subscription = this.Subscription;
    let school_id = subscription.school_id;
    let class_id = subscription.class_id;
    let subscriptionObj = {
      email: this.email,
      firstName: this.firstName,
      user_id: this.user_id,
      subscription_id: subscription.id,
      school_id: school_id,
      class_id: class_id,
      amount: subscription.amount,
      discount_amt: subscription.discount_amt,
      paid_amount: subscription.total_amount,
      frequency_value: subscription.subscription_freq_types.frequency_value,
      freq_type_id: subscription.freq_type_id,
      status: 1,
      payment_response_id: razorpay_payment_id
    }

    this.subscriptionService.createSubscription(subscriptionObj).subscribe((data: any)=>{
      //console.log(data)
      if(data.status){
        if(this.schoolCode == 'MRKSTRIAL') { //Move the  Marsksharks Trial School user to OKS School
          this.updateSchoolClass(school_id);
          this.updateUserClass(school_id);  
          // this.router.navigate(['/paymentTrialSuccess']);
          this.zone.run(()=> {
            this.router.navigate(['/paymentTrialSuccess']);
          });
        } else {
          this.zone.run(()=> {
            this.router.navigate(['/paymentSuccess']);
          });
          // this.router.navigate(['/paymentSuccess']);
        }
      } else {
        // this.router.navigate(['/paymentError']);
        this.zone.run(()=> {
          this.router.navigate(['/paymentError']);
        });
      }
    });
  }

  updateSchoolClass(school_id){
    let params = {
      trialSchoolId: school_id,
      quiz_user_id: this.quiz_user_id
    }
    this.subscriptionService.updateSchoolClass(params).subscribe((data: any)=> {

    });
  }

  updateUserClass(school_id){
    let params = {
      trialSchoolId: school_id,
      user_id: this.user_id
    }
    this.subscriptionService.updateUserClass(params).subscribe((data: any)=> {

    });
  }
}
