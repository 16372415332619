import {
  Component,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
  DoCheck,
  Output
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styles: [
    `
    Html, body{
      height:100%;
        margin:0px;
        padding:0px;
    }

    .container {
      height:100%;
        margin: 0 auto;
        padding:0px;
        position: relative;
        top: 200px;
    }
    .grandParentContaniner{
            display:table;
            height:100%;
            margin: 0 auto;
    }
    .parentContainer{
        display:table-cell;
        vertical-align:middle;
    }
    .parentContainer .loginForm{
        padding:10px;
        background: #fff;
        border: 1px solid #ddd;
        width:400px;  /*  your login form width */    display:table-cell;
        display:table-cell;
        vertical-align:middle;

    }
    .footer {
      max-height: 18px;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1rem;
      background-color: #efefef;
      text-align: center;
    }

    .btn-pad {
      margin-left: 10px;
    }
    `
  ]
})

export class DownloadMsReportComponent implements OnInit {

  @Output() isAuth = new EventEmitter<boolean>();
  model: any = {};
  loading = false;
  returnUrl: string;
  errorMessage: any;
  userData: any = {};
  customFilters: any = {};
  username: string;
  user_id: number;
  type: any;
  from_date: any;
  to_date: any;
  report_type: any;
  school_id: any;
  queryString: any;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authenticationService.logout();

    this.model.username = '';
    this.model.password = '';

    // this.returnUrl = '/student-report';

    // this.route.queryParams.subscribe((params: any) => {
    //   this.customFilters = JSON.parse(params.customFilters);
    //   console.log(this.customFilters);
    //   this.model.username = this.customFilters.email;
    //   this.type = this.customFilters.type;
    // });

    this.route.queryParams.subscribe((params: any) => {
      this.queryString = params;
      if (Object.keys(params).length > 0) {
        Object.keys(params).forEach((pKey) => {
          params = pKey;
        });
        params = atob(decodeURIComponent(params));
        params = params.split('&');
        this.username = (params ? params[0].split('=') : '');
        this.username = (this.username ? this.username[1] : '');
        this.user_id = (params ? params[1].split('=') : '');
        this.user_id = +(this.user_id ? this.user_id[1] : '');
        this.from_date = (params ? params[2].split('=') : '');
        this.from_date = (this.from_date ? this.from_date[1] : '');
        this.to_date = (params ? params[3].split('=') : '');
        this.to_date = (this.to_date ? this.to_date[1] : '');
        this.report_type = (params ? params[4].split('=') : '');
        this.report_type = (this.report_type ? this.report_type[1] : '');
        this.type = (params ? params[5].split('=') : '');
        this.type = (this.type ? this.type[1] : '');
        // this.school_id = (params ? params[6].split('=') : '');
        // this.school_id = +(this.school_id ? this.school_id[1] : '');
        this.model.username = this.username;
        this.customFilters = {
          from_date: this.from_date,
          to_date: this.to_date,
          report_type: this.report_type,
          type: this.type,
          school_id: this.school_id
        }
        // console.log(this.username);
      }
    });
  }



  login() {
    this.loading = true;
    this.authenticationService.login(this.model.username, this.model.password).subscribe(
      data => {
        if (data.status == false) {
          this.router.navigate(['/download-report'],{queryParamsHandling: 'preserve'});
          this.errorMessage = data;
        } else {
          this.authenticationService.getUserDetails({ username: this.model.username }).subscribe(
            userDataVal => {
              // console.log(userDataVal);
              //  let userData = JSON.parse(result);
              if (data.status == false) {
                this.router.navigate(['/download-report'],{queryParamsHandling: 'preserve'});
                this.errorMessage = userDataVal.message;
              } else if (userDataVal.data.id) {
                if (userDataVal.data.userData.length > 0) {
                  this.userData.username = userDataVal.data.username;
                  this.userData.isAuthenticated = true;
                  this.userData.token = data.data.token;
                  this.userData.id = data.data.userId;
                  this.userData.userData = userDataVal.data.userData;
                  this.userData.user_assigned_subjects = userDataVal.data.user_assigned_subjects;
                  this.userData.headerVal = data.data.headerVal;
                  localStorage.setItem('currentUser', JSON.stringify(this.userData));
                  if(this.type == 'user'){
                    this.router.navigate(['student-report'], {
                      // queryParams: {customFilters: JSON.stringify(this.customFilters)}
                      queryParams: this.queryString
                    });
                  }else if(this.type == 'school'){
                    this.router.navigate(['school-report'], {
                      queryParams: this.queryString
                    });
                  }
                  this.isAuth.emit(true);
                } else {
                  this.router.navigate(['/download-report'],{queryParamsHandling: 'preserve'});
                  this.errorMessage = { message: "User is not assigned. Please try again" };
                }
              } else {
                this.router.navigate(['/download-report'],{queryParamsHandling: 'preserve'});
                this.errorMessage = userDataVal.message;
              }

            }
          );
        }
        this.loading = false;
      },
      error => {
        //  console.log(error);
        this.loading = false;
      }
    );
  }

  reset(){
    this.model.password = '';
  }
}

