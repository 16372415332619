import { Component, OnInit, Input, Output } from '@angular/core';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/_services';
import { MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-join-class',
  template: `
  <button *ngIf="userDataVal!=='';else noClass" class="mat-raised-button mat-primary" style="width:130px;" 
    (click)="openClass()">
    Join
  </button>
  <ng-template #noClass>No Class Available</ng-template>
  `,
  styles: []
})
export class JoinClassComponent implements OnInit {
  @Output() joinClass = new Subject();
  @Input() schoolData;
  params: any;
  currentUser: any;
  userid: number;
  userName: string;
  userType: any;
  userTypeVal: any;
  schoolId: number;
  classId: number;
  sectionId: number;
  subjectId: number;
  onlineClassUrl = environment.onlineClassUrl;
  userDataVal: any;
  token: string;
  tokenStatus  =false;
  constructor(private backendService: BackendService,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userid = this.currentUser.id;
    this.token = this.currentUser.token;
    this.params = {
      school_id: this.schoolData.school_id,
      class_id: this.schoolData.class_id,
      section_id: this.schoolData.section_id,
      subject_id: this.schoolData.subject_id
    }
    this.userDataVal = '';
    // console.log(this.params);
    this.backendService.getZoomMeeting(this.params).subscribe((data: any) => {
      if(data.length>0){        
        let zoomClassDate = new Date(data[0].created_on);
        zoomClassDate.setMinutes(zoomClassDate.getMinutes() + 120);
        let zoomClassTime = zoomClassDate.getTime();
        let currTime = new Date().getTime();
        if(zoomClassTime >= currTime){
          this.userType = this.currentUser.userData[0].user_type;
          this.userName = this.currentUser.userData[0].studentName;
          this.schoolId = this.schoolData.school_id;
          this.classId = this.schoolData.class_id;
          this.sectionId = this.schoolData.section_id;
          this.subjectId = this.schoolData.subject_id;
          this.userDataVal =  "user_id="+this.userid+"&user_type="+this.userType+"&school_id="+this.schoolId+"&class_id="+this.classId+"&section_id="+this.sectionId+"&subject_id="+this.subjectId+"&userName="+this.userName;
          this.userDataVal = btoa(this.userDataVal);
        }
      }
    });
  }

  chkUserToken(){
    
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  openClass(){    
    // console.log(this.userDataVal);
    let paramsVal = {user_id: this.userid, token: this.token};
    // console.log(paramsVal);
    return this.authService.chkUserToken(paramsVal).subscribe(
      data =>{ 
        this.tokenStatus = data.status;
        if(!this.tokenStatus){
          this.openSnackBar("You've been logged in different Computer", 'Close');
          this.authService.logout();
          //return false;
        } else {
          window.open(this.onlineClassUrl+'?'+this.userDataVal,"Nifsoft Online Class",'height=650,width=700');
        }
        this.joinClass.next();
      }
    )    
  }
}
