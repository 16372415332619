import { Component, OnInit, ElementRef, ViewChildren, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeworkService } from '../homework.service';
import { FormGroup, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { GenericValidator } from '../../shared/generic-validator';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Observable } from 'rxjs';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import * as ClassicEditor from '../../../assets/js/ck-editor-mathtype/ckeditor.js';
import stripHtml from './../../../../node_modules/string-strip-html';

@Component({
  selector: 'app-homework-details',
  templateUrl: './homework-details.component.html',
  styleUrls: ['./homework-details.component.css']
})
export class HomeworkDetailsComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @ViewChild('HWFile') selectFile: ElementRef;

  status = { 1: 'Active', 2: 'Inactive' };
  displayMessage: { [key: string]: string } = {};
  currentUser: any;
  token: string;
  user_id: number;
  homework_id: number;
  pageTitle = 'Homework Details';
  homeworkData: any;
  homeworkForm: FormGroup;
  formSubmit = false;
  showVal = false;
  fileData: File = null;
  uploadResponse = { status: '', message: '', file: '' };
  fileUploadProgress: string = null;
  apiUrl = environment.apiUrl;
  homeworkRemarks = [];
  submission_id: number;
  showBtn = false;
  teacher_id: number;
  errorMessage: any;
  expiration_date: any;
  showForm = true;
  remark_id: number;
  class_id: number;

  public Editor = ClassicEditor;
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private homeworkService: HomeworkService
  ) {
    this.validationMessages = {
      remarks: {
        required: 'Remark is required.'
      },
      status: {
        required: 'status is required.'
      },
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.token = this.currentUser.token;
      this.user_id = this.currentUser.id
      this.class_id = this.currentUser.userData[0].class_id;
    }

    this.homeworkForm = this.fb.group({
      remarks: ['', [Validators.required]],
      filepath: ['', ''],
      status: ['', [Validators.required]]
    });

    this.route.params.subscribe(params => {
      this.homework_id = parseInt(params.homework_id);
      this.teacher_id = parseInt(params.teacher_id);
      this.getHomework();
    });
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.homeworkForm.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.homeworkForm);
    });
  }

  getHomework() {
    let obj = {
      homework_id: this.homework_id,
      user_type: 0,
      teacher_id: this.teacher_id,
      student_id: this.user_id
    }
    this.homeworkService.getHWDetails(obj).subscribe((homeworkData: any) => {
      // console.log(homeworkData);
      this.homeworkData = homeworkData.data;
      let currentDate = new Date();
      this.expiration_date = this.homeworkData.expiration_date;
      if(currentDate.toISOString() <= this.expiration_date){
        this.showForm = true;
      }else{
        this.showForm = false;
      }
      this.homeworkRemarks = this.homeworkData.homeworkRemarks;
      // this.homeworkRemarks.reverse();
      this.homeworkRemarks.forEach(hr => {
        if (hr.status == 2 && hr.created_by == this.user_id) {
          let remark = this.getDescription(hr.remarks);
          this.homeworkForm.patchValue({
            remarks: remark,
            status: hr.status
          });
          this.submission_id = hr.submission_id
          this.remark_id = hr.id;
          if (hr.homeworkFile.length > 0) {
            this.homeworkForm.patchValue({
              filepath: hr.homeworkFile[0].filepath
            });
            this.showBtn = true;
          }
        }
      })
    });
  }


  saveHomework(): void {
    // if (this.homeworkForm.dirty && this.homeworkForm.valid) {

      if (this.submission_id) {
        let obj = Object.assign({},
          { created_by: this.user_id },
          { given_to: this.teacher_id },
          { homework_id: this.homework_id },
          { submission_id: this.submission_id },
          { class_id: this.class_id},
          this.homeworkForm.value);
        this.homeworkService.edithomeWorkSubmit(obj).subscribe((data: any) => {
          if (data.status){
            this.onSaveComplete("Homework submitted successfully");
          }else{
            this.onError(data.message);
          }
        });
      } else {
        let obj = Object.assign({},
          { created_by: this.user_id },
          { given_to: this.teacher_id },
          { homework_id: this.homework_id },
          { class_id: this.class_id},
          this.homeworkForm.value);
        this.homeworkService.homeWorkSubmit(obj).subscribe((data: any) => {
          if (data.status){
            this.onSaveComplete("Homework submitted successfully");
          }else{
            this.onError(data.message);
          }
        });
      }

    // }
  }

  onSaveComplete(message): void {
    this.openSnackBar(message, 'Close');
    this.router.navigate(['/studentHomework']);
  }

  onError(message): void {
    this.openSnackBar(message, 'Close');
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  onFormSubmit(): void {
    this.formSubmit = true;
  }


  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.homeworkForm.controls.filepath.setValue(null);
      this.showVal = false;
      this.uploadResponse.message = '';
      this.fileData = event.target.files[0] as File;
      const fileName = this.fileData.name;
      if (fileName.indexOf('.jpg') > 0 || fileName.indexOf('.jpeg') > 0 ||
        fileName.indexOf('.pdf') > 0 || fileName.indexOf('.txt') > 0 ||
        fileName.indexOf('.png') > 0 || fileName.indexOf('.gif') > 0 || 
        fileName.indexOf('.mp4') > 0 || fileName.indexOf('.html') > 0) { 
          this.errorMessage = "";
        }
      else {
        this.fileUploadProgress = '';
        this.errorMessage = "Only .pdf, .jpg, .png, .txt, .gif, mp4, html files are allowed"
        return;
      }
      const formData = new FormData();
      formData.append('files', this.fileData);
      this.fileUploadProgress = '0%';
      // console.log(formData);
      this.http.post(`${this.apiUrl}/containers/upload`, formData, {
        reportProgress: true,
        observe: 'events'
      })
        .subscribe(events => {
          if (events.type === HttpEventType.UploadProgress) {
            this.fileUploadProgress = Math.round((events.loaded / events.total) * 100) + '%';
            // console.log(this.fileUploadProgress);
          } else if (events.type === HttpEventType.Response) {
            let response;
            response = events.body;
            this.homeworkForm.controls.filepath.setValue(response.url);
          }
        });
    }
  }

  openHW(filepath) {
    window.open(filepath, '_blank');
  }

  draftHW() {
    window.open(this.homeworkForm.value.filepath, '_blank');
  }

  decodeEntities(str) {
    // this prevents any overhead from creating the object each time
    const element = document.createElement('div');
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^''>]|'[^']*'|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }
    str = str.replace(/src="/g, `src="`);
    return str;
  }
  
  getDescription(description){
    const stripOption = {
      ignoreTags: ['xml'],
      onlyStripTags: ['span'],
      stripTogetherWithTheirContents: ['script'],
      skipHtmlDecoding: false,
      returnRangesOnly: false,
      trimOnlySpaces: false,
      dumpLinkHrefsNearby: {
        enabled: false,
        putOnNewLine: false,
        wrapHeads: '',
        wrapTails: ''
      }
    };
  
    let desc = this.decodeEntities(description);
    desc = desc.replace(/\r?\n|\r/g, '');
    desc = desc.replace(/>\s+</g, '><');
    let dstr1 = desc.indexOf('annotation');
    do {
      if (desc.indexOf('/annotation') > -1) {
        const dstr3 = '/annotation';
        const dstr2 = desc.indexOf('/annotation') + dstr3.length + 2;
        const dres = desc.substring(dstr1, dstr2);
        desc = desc.replace(dres, '');
      }
      dstr1 = desc.indexOf('annotation');
    }while (dstr1 !== -1);
    desc = stripHtml(desc, stripOption);
    return desc;
    // console.log(des)
  
  }

  deleteFile() {
    var result = confirm("Are you sure, you want to delete the homework file?");
    if (result) {
      let obj = { homework_id: this.homework_id, remark_id: this.remark_id }
      this.homeworkService.deleteHWFile(obj).subscribe((data: any) => {
        if(data.status){
          this.showBtn = false;
          this.homeworkForm.controls.filepath.setValue(null);
        }
      });
    }
  }

  removeFile() {
    var result = confirm("Are you sure, you want to delete the homework file?");
    if (result) {
      let HWFile = this.homeworkForm.value.filepath
      let homework = HWFile.split("/");
      let splitFile = homework[homework.length-2] + '/' + homework[homework.length-1];
      let obj = { filepath: splitFile }
      this.homeworkService.removeHWfile(obj).subscribe((response: any) => {
        if(response.status){
          this.selectFile.nativeElement.value = '';
          this.fileUploadProgress = null;
          this.homeworkForm.controls.filepath.setValue(null);
        }
      });
    }
  }

}
