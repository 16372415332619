import {
  Component,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
  DoCheck,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MaterialModule } from '../shared/material.module';
import { AuthenticationService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import { BackendService } from '../_services/backend.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AlertMessageComponent } from './alert-message.component';

@Component({
  selector: 'login',
  // moduleId: module.id.toString(),
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css',
    './login-util.css',
    './login-main.css'
  ]
})

export class LoginComponent implements OnInit, AfterViewInit {
  @Output() isAuth = new EventEmitter<boolean>();
  // @ViewChild('logo') private logo: ElementRef;
  model: any = {};
  loading = false;
  returnUrl: string;
  errorMessage: any;
  userData: any = {};
  token: string;
  school_code: any;
  liveUrl = environment.liveUrl;
  school_logo: any;
  showLogo: boolean = true;
  showDiagMsg = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private backendService: BackendService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    // this.logo.nativeElement.remove();
    // this.route.params.subscribe(params => {
    //   this.school_code = params['school_code'];
    //   if (this.school_code) {
    //     console.log("school code get>>>>>>>>>>>>");
    //     this.getSchool(this.school_code);
    //   } else {
    //     console.log("no school code   >>>>>>>>>>>>");
    //     this.showLogo = false;
    //   }
    // });
    this.authenticationService.logout();
    this.model.username = '';
    this.model.password = '';

    this.returnUrl = '/dashboard';
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.showDiagMsg = localStorage.getItem('showDialog');
      if(this.showDiagMsg == null){
        //this.showDialog();
      }      
    }, 1000);    
  }


  prelogin() {//console.log('mab = '+this.model);
    this.loading = true;

    this.authenticationService.getUserClasses({ username: this.model.username }).subscribe(
      userDataVal => {
        //console.log('Msb = '+userDataVal.data.userData[0].class_id);
        //  let userData = JSON.parse(result);
        //console.log(userDataVal)
        if (userDataVal) {
          this.login(this.model.username, this.model.password);
          /*
          if(userDataVal.data.length > 1 || (userDataVal.data.length == 1 && userDataVal.data[0].class_id != 6)) {
          //if (userDataVal.data[0].class_id != 6) {  
          this.logintemp(this.model.username, this.model.password);
          } else {
            this.login(this.model.username, this.model.password);
          }
          */
        } else {
          this.router.navigate(['/login']);
          this.errorMessage = { message: "User is not assigned. Please try again" };
        }
        

      }
    );
  }
  

  login(username, password) {
    //this.loading = true;

    this.authenticationService.login(username, password).subscribe(
      data => {
        if (data.status == false) {
          this.router.navigate(['/login']);
          this.errorMessage = data;
        } else {
          this.authenticationService.getUserDetails({ username: username }).subscribe(
            userDataVal => {
              // console.log(userDataVal);
              //  let userData = JSON.parse(result);
              if (data.status == false) {
                this.router.navigate(['/login']);
                this.errorMessage = userDataVal.message;
              } else if (userDataVal.data.id) {
                if (userDataVal.data.userData.length > 0) {
                  this.userData.username = userDataVal.data.username;
                  this.userData.isAuthenticated = true;
                  this.userData.token = data.data.token;
                  this.userData.id = data.data.userId;
                  this.userData.userData = userDataVal.data.userData;
                  this.userData.user_assigned_subjects = userDataVal.data.user_assigned_subjects;
                  this.userData.adminUser = userDataVal.data.adminUser;
                  this.userData.headerVal = data.data.headerVal;
                  this.userData.adminUser = userDataVal.data.adminUser;
                  this.userData.currentSelSchoolId = userDataVal.data.userSchools[0].id;
                  this.userData.currentClassId = userDataVal.data.userData[0].class_id;
                  // this.userData.currentSectionId = userDataVal.data.userData[0].section_id;
                  this.userData.userSchools = userDataVal.data.userSchools;
                  this.userData.countryDetail = userDataVal.countryDetail
                  localStorage.setItem('currentUser', JSON.stringify(this.userData));
                  // this.setLeftNavigationMenus();
                  // this.authenticationService.sendCurrUserDetails(this.userData);
                  // this.authenticationService.isSubscribed();
                  // this.router.navigate([this.returnUrl]);
                  // this.isAuth.emit(true);
                  // this.chkSubscription();
                  this.setLeftNavigationMenus();
                  this.authenticationService.sendCurrUserDetails(this.userData);
                  this.router.navigate([this.returnUrl]);
                  this.isAuth.emit(true);
                } else {
                  this.router.navigate(['/login']);
                  this.errorMessage = { message: "User is not assigned. Please try again" };
                }
              } else {
                this.router.navigate(['/login']);
                this.errorMessage = userDataVal.message;
              }

            }
          );
        }
        this.loading = false;
      },
      error => {
        //  console.log(error);
        this.loading = false;
      }
    );
  }

  logintemp(username, password) {
    //this.loading = true;

    this.authenticationService.logintemp(username, password).subscribe(
      data => {
        if (data.status == false) {
          this.router.navigate(['/login']);
          //console.log(data)
          this.errorMessage = data;
        } else {
          this.authenticationService.getUserDetails({ username: username }).subscribe(
            userDataVal => {
              // console.log(userDataVal);
              //  let userData = JSON.parse(result);
              if (data.status == false) {
                this.router.navigate(['/login']);
                this.errorMessage = userDataVal.message;
              } else if (userDataVal.data.id) {
                if (userDataVal.data.userData.length > 0) {
                  this.userData.username = userDataVal.data.username;
                  this.userData.isAuthenticated = true;
                  this.userData.token = data.data.token;
                  this.userData.id = data.data.userId;
                  this.userData.userData = userDataVal.data.userData;
                  this.userData.user_assigned_subjects = userDataVal.data.user_assigned_subjects;
                  this.userData.adminUser = userDataVal.data.adminUser;
                  this.userData.headerVal = data.data.headerVal;
                  this.userData.adminUser = userDataVal.data.adminUser;
                  this.userData.currentSelSchoolId = userDataVal.data.userSchools[0].id;
                  this.userData.currentClassId = userDataVal.data.userData[0].class_id;
                  // this.userData.currentSectionId = userDataVal.data.userData[0].section_id;
                  this.userData.userSchools = userDataVal.data.userSchools;
                  this.userData.countryDetail = userDataVal.countryDetail
                  localStorage.setItem('currentUser', JSON.stringify(this.userData));
                  // this.setLeftNavigationMenus();
                  // this.authenticationService.sendCurrUserDetails(this.userData);
                  // this.authenticationService.isSubscribed();
                  // this.router.navigate([this.returnUrl]);
                  // this.isAuth.emit(true);
                  // this.chkSubscription();
                  this.setLeftNavigationMenus();
                  this.authenticationService.sendCurrUserDetails(this.userData);
                  this.router.navigate([this.returnUrl]);
                  this.isAuth.emit(true);
                } else {
                  this.router.navigate(['/login']);
                  this.errorMessage = { message: "User is not assigned. Please try again" };
                }
              } else {
                this.router.navigate(['/login']);
                this.errorMessage = userDataVal.message;
              }

            }
          );
        }
        this.loading = false;
      },
      error => {
        //  console.log(error);
        this.loading = false;
      }
    );
  }

  getSchool(school_code) {
    let obj = { school_code: school_code }
    this.backendService.getSchoolByCode(obj).subscribe((data: any) => {
      //console.log(data);
      this.school_logo = data.data.school_logo;
      // this.logo.nativeElement.setAttribute('src', this.school_logo);
      //console.log(this.school_logo);
      this.showLogo = true;
    })
  }

  setLeftNavigationMenus() {
    let params = {
      user_type_id: this.userData.adminUser[0].user_type_id,
      user_id: this.userData.adminUser[0].id,
      parent_id: 0
    };
    this.authenticationService.getLeftNavigationMenus(params).subscribe((navData: any) => {
      if (navData.status) {
        // console.log(navData)
        let appitems = navData.data;
        let permissions = this.mapArr(appitems);
        localStorage.setItem('menus', JSON.stringify(permissions));
        this.authenticationService.sendLeftNavMenus(appitems);
      }
    });
  }

  mapArr(arrVal, newArr = []): any {
    arrVal.forEach(Array => {
      newArr.push(Array);
      if (Array.items) {
        this.mapArr(Array.items, newArr);
      }
    })
    return newArr;
  }

  chkSubscription() {
    let obj = {
      user_id: this.userData.adminUser[0].id,
      school_id: this.userData.userData[0].school.id,
      clas_id: this.userData.userData[0].class.id
    }
   // console.log(obj)
    this.authenticationService.getUserSubById(obj).subscribe((data: any) => {
      //console.log(data);
      if (data.data) {
        this.setLeftNavigationMenus();
        this.authenticationService.sendCurrUserDetails(this.userData);
        this.router.navigate([this.returnUrl]);
        this.isAuth.emit(true);
      }else{
        this.router.navigate(['/paymentSubscription']);
      }
    })
  }

  // get getImage() {
  //   return !this.showLogo ? this.liveUrl + '/assets/images/company_logo.png' : this.school_logo
  // }

  showDialog() {
    localStorage.setItem('showDialog','1');

    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(AlertMessageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      this.showDiagMsg = null;
      localStorage.removeItem("showDialog");
    });
  }
}
