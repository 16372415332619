// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  onlineClassUrl: '',
  //apiUrl: 'http://localhost:4010/api',
  apiUrl: 'https://www.nifsoft.com:8443/api', 
  //apiUrl: 'https://34.131.169.83:8443/api',
  //onlineClassUrl: 'https://marksharks.com/online_class/index.php',
  //liveUrl: 'http://localhost:4201',
  liveUrl: 'https://www.nifsoft.com',
  //liveUrl: 'http://34.131.169.83',
  imgUrl: '', //'https://storage.googleapis.com/lms-app',
  //adminUrl: 'https://34.131.169.83:8443',
  adminUrl: 'https://www.nifsoft.com:8443',
  msUrl: '', //'https://storage.googleapis.com/marksharks-content',
  contentUrl: '', //'https://storage.googleapis.com/marksharks-content/msplus-content',
  appContentUrl: '', //'https://storage.googleapis.com/marksharks-content/app-content',
  API_URL: 'https://www.googleapis.com/youtube/v3/search',
  API_TOKEN: 'AIzaSyDmNbqZfOxBYq3rxE-hy5qIwqhGyqGX_dg',
  quesUploadUrl: '', //'http://ec2-3-95-207-0.compute-1.amazonaws.com/LMS/convert_html.php',
  crmUrlApi: '', //'https://mcrm.marksharks.com:3005/api',
  razorPayKey: 'rzp_live_LPVj1mhLp5MD8r', //'rzp_test_h6gvOZC5j1bCuu'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
