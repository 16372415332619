import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models';
import { environment } from '../../environments/environment';



@Injectable()
export class AuthenticationService {

  private leftNavMenus = new Subject<any>();
  private currentUser = new Subject<any>();

  private apiUrl = environment.apiUrl;
  private userVal = {
    id: 0,
    isAuthenticated: false
  };
  constructor(private http: Http) { }

  logintemp(username, password): Observable<any> {
    return this.http.post(`${this.apiUrl}/quiz_users/applogin`, { username, password })
      .pipe(map((response) => {
        // login successful if there's a jwt token in the response
        let user = response.json();
        this.userVal.id = user.userId;
        if (user && user.userId) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // this.userVal.isAuthenticated = true;
          //localStorage.setItem('currentUser', JSON.stringify(user));
          // return response.toString();
          return this.userVal;
        }
        return user;
      }));
  }

  login(username, password): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/userlogin`, { username, password })
      .pipe(map((response) => {
        // login successful if there's a jwt token in the response
        let user = response.json();
        this.userVal.id = user.userId;
        if (user && user.userId) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // this.userVal.isAuthenticated = true;
          //localStorage.setItem('currentUser', JSON.stringify(user));
          // return response.toString();
          return this.userVal;
        }
        return user;
      }));

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }

  isAuthenticated() {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(user)
    return user && user.isAuthenticated ? true : false;
  }

  autologin(username, user_id, token): Observable<any> {

    return this.http.post(`${this.apiUrl}/users/applogin`, { username, user_id, token })
      .pipe(map((response) => {
        localStorage.removeItem('currentUser');
        // login successful if there's a jwt token in the response
        let user = response.json();
        //console.log('autologin user = ' + JSON.stringify(user));
        if (user && user.id) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          //user.isAuthenticated = true;
          //localStorage.setItem('currentUser', JSON.stringify(user));
          ///return response.toString();
          let userName = username;
          let obj = {
            username: userName
          }
         this.getUserDetails(obj).subscribe((data : any) => {
          //console.log(data);
          let userDataVal = data.data;
          userDataVal.countryDetail = data.countryDetail;
          userDataVal.isAuthenticated = true;
          userDataVal.currentSelSchoolId = data.data.userSchools[0].id;
          userDataVal.currentClassId = data.data.userData[0].class_id;
          //console.log(userDataVal);
          localStorage.setItem('currentUser', JSON.stringify(userDataVal));
          //let newData =  JSON.parse(localStorage.getItem('currentUser'));
          //console.log(newData)
          //return userDataVal;
         })

        }
        return user;
      }));
  }

  getUserClasses(paramsVal: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/quiz_users/getUserClasses`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserDetails(paramsVal: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/quiz_users/getuserdetails`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  chkUserToken(paramsVal: any) {
    return this.http.post(`${this.apiUrl}/quiz_users/chkUserToken`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  userVerification(paramsVal: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/quiz_users/userVerification`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getLeftNavigationMenus(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/permissions/getUserPermissions`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getAllowedLinks(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/permissions/allowedLinks`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserSubById(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_users/getUserSubById`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  sendLeftNavMenus(leftNavMenus) {
    this.leftNavMenus.next(leftNavMenus);
  }

  getLeftNavMenus(): Observable<any> {
    return this.leftNavMenus.asObservable();
  }

  sendCurrUserDetails(currentUser) {
    this.currentUser.next(currentUser);
  }

  getCurrUserDetails(): Observable<any> {
    return this.currentUser.asObservable();
  }

  isSubscribed(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/chkUserSubscription`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  isSubscribedLiferay(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/chkUserSubscriptionLiferay`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }
  // isSubscribed() {

  //   this.http.post(`${this.apiUrl}/subscriptions/chkUserSubscription`, obj)
  //     .map((response: Response) => {
  //       console.log(response);
  //       return response.json();
  //       // return response.json().data ? true : false;
  //     })
  //     .catch(this.handleError);
  // }

  private handleError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error.json().error);
    return Observable.throw(error.json().error || 'Server error');
  }
}
