import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services';
import { float } from 'html2canvas/dist/types/css/property-descriptors/float';
import { UserService } from '../../user.service';
import { RazorpayComponent } from 'src/app/subscription/payment-subscription/razorpay/razorpay.component';
import { environment } from 'src/environments/environment';
import { WindowRefService } from 'src/app/_services/window-ref.service';
import { tr } from 'date-fns/locale';
import { FLAGS } from '@angular/core/src/render3/interfaces/view';

@Component({
  selector: 'app-user-payment-confirm',
  templateUrl: './user-payment-confirm.component.html',
  styleUrls: ['./user-payment-confirm.component.css']
})
export class UserPaymentConfirmComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  razorKey = environment.razorPayKey;
  redirectUrl = environment.liveUrl;
  loading: boolean = false;
  liveUrl = environment.liveUrl;
  userSubForm: FormGroup;
  displayMessage: { [key: string]: string } = {};
  boards: any;
  errorMessage: any;
  schoolSubForm: FormGroup;
  currentUser: any;
  classList: any;
  schoolList: any;
  loginUser_id: number;
  user_id: any;
  type_order: any;
  schoolArr = [];
  classArr = [];
  customFilters: any;
  userData: any;
  frequencyTypeList: any;
  totalAmount: number;
  userSubId: number;
  userSubscription: any;
  school_id: number;
  class_id: number;
  disableButton: boolean = false;

  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  countryList: any;
  userEmail: any;
  checkout_amount: number;
  enableSubmit: boolean = false;
  newFormData: any;
  admin_id: any;
  product: string;
  sku: string;
  frequency_type_value: any;
  statesList: any;
  errorMessageResponseId: any;
  sandbox_data: any;

  constructor(
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private http: HttpClient,
    //private httpHeader: HttpHeaders,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private backendService: BackendService,
    private authenticationService: AuthenticationService,
    private winRef: WindowRefService,
    private zone: NgZone
  ) {
    this.validationMessages = {
      school_id: {
        required: 'school is required.'
      },
      class_id: {
        required: 'Class is required.'
      },
      freq_type_id: {
        required: 'Frequency Type is required.'
      },
      amount: {
        required: 'Amount is required.'
      },
      total_amount: {
        required: 'Total amount is required.'
      },
      status: {
        required: 'Status is required.'
      },
      firstName: {
        required: 'First name is required.'
      },
      address: {
        required: 'Address is required.'
      },
      city: {
        required: 'City is required.'
      },
      country_id: {
        required: 'Country is required.'
      },
      state: {
        required: 'State is required.'
      },
      pincode: {
        required: 'Pincode is required.'
      },
      contactNumber: {
        required: 'Contact number is required.'
      },
      email: {
        required: 'Email is required.'
      },
      payment_type: {
        required: 'Payment Method is required.'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.user_id = this.currentUser.id;
    if (this.currentUser) {
      // this.user_id = this.currentUser.id;
      // console.log(this.currentUser)
      this.admin_id = this.currentUser.adminUser[0].id;
    }

    this.route.params.subscribe(params => {
      this.user_id = params['user_id'];
      //this.getUser()
      // this.userSubForm.controls.user_id.setValue(this.user_id);

    });


    this.userSubForm = this.fb.group({
      user_id: ['', [Validators.required]],
      school_id: ['', [Validators.required]],
      class_id: ['', [Validators.required]],
      freq_type_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      discount_amt: ['', ''],
      total_amount: ['', [Validators.required]],
      checkout_amount: ['', [Validators.required]],
      payment_type: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', ''],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country_id: ['', [Validators.required]],
      state: ['', Validators.required],
      pincode: ['', [Validators.required]],
      contactNumber: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required]],
      received_by: ['', ''],
      emi_amount: ['', ''],
      no_of_installment: ['', ''],
      down_payment: ['', ''],
      subscription_id: ['', ''],
      gst_amount: ['', ''],
      gst_no: ['', ''],
      state_id: ['', ''],
      cheque_receipt_no: ['', ''],
      down_payment_type: ['', '']
    });



    // Disable form fields
    //this.userSubForm.controls.user_id.disable();
    this.userSubForm.controls.school_id.disable();
    this.userSubForm.controls.class_id.disable();
    this.userSubForm.controls.freq_type_id.disable();
    this.userSubForm.controls.amount.disable();
    this.userSubForm.controls.discount_amt.disable();
    this.userSubForm.controls.total_amount.disable();
    this.userSubForm.controls.checkout_amount.disable();
    //this.userSubForm.controls.payment_type.disable();
    this.userSubForm.controls.firstName.disable();
    this.userSubForm.controls.lastName.disable();
    this.userSubForm.controls.address.disable();
    this.userSubForm.controls.city.disable();
    this.userSubForm.controls.country_id.disable();
    this.userSubForm.controls.state.disable();
    this.userSubForm.controls.pincode.disable();
    this.userSubForm.controls.contactNumber.disable();
    this.userSubForm.controls.email.disable();
    this.userSubForm.controls.received_by.disable();
    this.userSubForm.controls.emi_amount.disable();
    this.userSubForm.controls.no_of_installment.disable();
    this.userSubForm.controls.down_payment.disable();
    this.userSubForm.controls.gst_amount.disable();
    this.userSubForm.controls.gst_no.disable();
    this.userSubForm.controls.cheque_receipt_no.disable();
    this.userSubForm.controls.down_payment_type.disable();

    this.userService.getCountries().subscribe((data: any) => {
      this.countryList = data.data;
    })

    // this.userService.getStates().subscribe((data: any) => {
    //   this.statesList = data;
    // })

    this.backendService.getActiveSchools({}).subscribe((data: any) => {
      this.schoolList = data.data;
      //this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
    });

    this.subscriptionService.getAllFrequencyType({}).subscribe((data: any) => {
      // console.log(data);
      this.frequencyTypeList = data.data;
      localStorage.setItem('frequencyTypeList', JSON.stringify(this.frequencyTypeList));
    })

    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
      // console.log(this.classList)
    });

    this.subscriptionService.getSubPaymentFromData().subscribe(formData => {
       console.log(formData)
      if (formData.user_id !== undefined) {
        //this.enableSubmit = true;
        this.newFormData = formData;
        this.userSubForm.setValue(formData);
        //this.setProductSku(this.newFormData.freq_type_id);
        //this.userSubForm.patchValue(formData);
        if (this.userSubForm.valid) {
          //console.log('valid')
        } else {
          // console.log('invalid')
        }
      } else {
        this.enableSubmit = false;
      }


    });

  }


  subscribe() {
    // console.log('kd')
    this.loading = true;
    this.disableButton = true;
    let obj = {};
    obj = Object.assign({}, this.newFormData);
    //  console.log(obj)
    if (this.frequencyTypeList) {
      let freq_type_id = this.newFormData.freq_type_id;
      //console.log(this.frequencyTypeList)
      const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

      if (selectedDataProd) {
        const { frequency, frequency_value } = selectedDataProd;
        this.frequency_type_value = frequency_value
        // console.log('Frequency:', this.frequency_type_value);
      }

    }
    // console.log(this.newFormData)
    let subscriptionObj = {
      email: this.newFormData.email,
      firstName: this.newFormData.firstName,
      user_id: this.newFormData.user_id,
      subscription_id: this.newFormData.subscription_id,
      school_id: this.newFormData.school_id,
      class_id: this.newFormData.class_id,
      amount: this.newFormData.amount,
      discount_amt: this.newFormData.discount_amt,
      paid_amount: this.newFormData.checkout_amount,
      gst_amount: this.newFormData.gst_amount,
      freq_type_id: this.newFormData.freq_type_id,
      frequency_value: this.frequency_type_value,
      payment_type: this.newFormData.payment_type,
      payment_response_id: this.newFormData.payment_type,
      status: 1,
    }
     //console.log(subscriptionObj);
    this.subscriptionService.createSubscription(subscriptionObj).subscribe((data: any) => {
      //  console.log(data)
      if (data.status) {
        if (data.data.subscription.subscriptionId) {
          let tax_amount = this.newFormData.checkout_amount - this.newFormData.total_amount;
          // console.log('Tax:'+tax_amount)
          if (this.frequencyTypeList && this.classList) {
            let freq_type_id = this.newFormData.freq_type_id;
            //console.log(this.frequencyTypeList)
            const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

            if (selectedDataProd) {
              const { frequency, frequency_value } = selectedDataProd;
              // Patch the form values with the matched values in data
              //console.log('Frequency:', frequency);
              //  console.log('Frequency:', frequency_value);
              if (frequency_value === 1) {
                this.product = frequency_value + ' Month Plan';
              } else {
                this.product = frequency_value + ' Months Plan';
              }
              //console.log(this.product);
              this.sku = 'G' + this.newFormData.class_id + '_CBSE_' + frequency_value + 'M'
              //console.log(this.sku);
            }

          }
          let liferaySubObj = {
            order_id: data.data.subscription.subscriptionId,
            product: this.product,
            class_id: this.newFormData.class_id,
            email: this.newFormData.email,
            start_date: data.data.subscription.start_date,
            end_date: data.data.subscription.end_date,
          }

          // code when class_id is not 6
          if (this.newFormData.class_id != 6) {
            //  console.log(liferaySubObj)
            this.subscriptionService.createSubscriptionLiferay(liferaySubObj).subscribe((dataLiferay: any) => {
              //  console.log(dataLiferay);
              if (dataLiferay.status == true) {
                let invoiceObj = {
                  user_id: this.newFormData.user_id,
                  order_id: data.data.subscription.subscriptionId,
                  school_id: this.newFormData.school_id,
                  class_id: this.newFormData.class_id,
                  firstName: this.newFormData.firstName,
                  lastName: this.newFormData.lastName,
                  address: this.newFormData.address,
                  city: this.newFormData.city,
                  state: this.newFormData.state,
                  country_id: this.newFormData.country_id,
                  pincode: this.newFormData.pincode,
                  email: this.newFormData.email,
                  contactNumber: this.newFormData.contactNumber,
                  payment_type: this.newFormData.payment_type,
                  price: this.newFormData.amount,
                  discount_amount: this.newFormData.discount_amt,
                  tax_amount: tax_amount,
                  quantity: 1,
                  total_amount: this.newFormData.checkout_amount,
                  received_by: this.newFormData.received_by,
                  product: this.product,
                  sku: this.sku,
                  created_by: this.admin_id,
                  gst_no: this.newFormData.gst_no,
                  state_id: this.newFormData.state_id,
                  cheque_receipt_no: this.newFormData.cheque_receipt_no,
                }
                //console.log('not grad 6', invoiceObj);

                this.subscriptionService.addInvoice(invoiceObj).subscribe((dataInvoice: any) => {
                  // console.log(data);
                  if (dataInvoice.status == true) {
                    let obj = {}
                    this.loading = false;
                    this.disableButton = false;
                    this.subscriptionService.setSubPaymentFromData(obj)
                    this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');
                    // this.router.navigate(['/user']);
                    this.router.navigate(['/invoiceUser', this.user_id]);
                  } else {
                    this.loading = false;
                    this.disableButton = false
                    this.errorMessage = dataInvoice.message;
                  }
                },
                  (error) => {
                    this.loading = false;
                    this.disableButton = false;
                    this.errorMessage = 'An error occurred.';
                    console.error('An error occurred:', error);
                  })

              } else {
                this.loading = false;
                this.disableButton = false;
                this.errorMessage = dataLiferay.message;
              }
            },
              (error) => {
                this.loading = false;
                this.disableButton = false;
                this.errorMessage = 'An error occurred.';
                console.error('An error occurred:', error);
              });
          } else {

            //code when class_id is 6
            let invoiceObj = {
              user_id: this.newFormData.user_id,
              order_id: data.data.subscription.subscriptionId,
              school_id: this.newFormData.school_id,
              class_id: this.newFormData.class_id,
              firstName: this.newFormData.firstName,
              lastName: this.newFormData.lastName,
              address: this.newFormData.address,
              city: this.newFormData.city,
              state: this.newFormData.state,
              country_id: this.newFormData.country_id,
              pincode: this.newFormData.pincode,
              email: this.newFormData.email,
              contactNumber: this.newFormData.contactNumber,
              payment_type: this.newFormData.payment_type,
              price: this.newFormData.amount,
              discount_amount: this.newFormData.discount_amt,
              tax_amount: tax_amount,
              quantity: 1,
              total_amount: this.newFormData.checkout_amount,
              received_by: this.newFormData.received_by,
              product: this.product,
              sku: this.sku,
              created_by: this.admin_id,
              gst_no: this.newFormData.gst_no,
              state_id: this.newFormData.state_id,
              cheque_receipt_no: this.newFormData.cheque_receipt_no,
            }
            //console.log(invoiceObj);

            this.subscriptionService.addInvoice(invoiceObj).subscribe((dataInvoice: any) => {
              // console.log(data);
              if (dataInvoice.status == true) {
                let obj = {}
                this.loading = false;
                this.disableButton = false;
                this.subscriptionService.setSubPaymentFromData(obj)
                this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');
                // this.router.navigate(['/user']);
                this.router.navigate(['/invoiceUser', this.user_id]);
              } else {
                this.loading = false;
                this.disableButton = false;
                this.errorMessage = dataInvoice.message;
              }
            },
              (error) => {
                this.loading = false;
                this.disableButton = false;
                this.errorMessage = 'An error occurred.';
                console.error('An error occurred:', error);
              }
            )
          }
        } else {
          this.errorMessage = 'Server Error'
          this.loading = false;
          this.disableButton = false;
        }
      } else {
        this.loading = false;
        this.disableButton = false;
        this.errorMessage = 'Server Error. Please check all fields & retry! '+data.message;
      }
    }, (error) => {
      this.loading = false;
      this.disableButton = false;
      this.errorMessage = 'An error occurred.';
      console.error('An error occurred:', error);
    });

  }

  subscribeIndoCash(){

    this.loading = true;
    this.disableButton = true;
    let obj = {};
    obj = Object.assign({}, this.newFormData);

    //  console.log(obj)
    if (this.frequencyTypeList) {
      let freq_type_id = this.newFormData.freq_type_id;
      //console.log(this.frequencyTypeList)
      const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

      if (selectedDataProd) {
        const { frequency, frequency_value } = selectedDataProd;
        this.frequency_type_value = frequency_value
        // console.log('Frequency:', this.frequency_type_value);
      }

    }

    if (this.frequencyTypeList && this.classList) {
      let freq_type_id = this.newFormData.freq_type_id;
      //console.log(this.frequencyTypeList)
      const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

      if (selectedDataProd) {
        const { frequency, frequency_value } = selectedDataProd;
        // Patch the form values with the matched values in data
        //console.log('Frequency:', frequency);
        //  console.log('Frequency:', frequency_value);
        if (frequency_value === 1) {
          this.product = frequency_value + ' Month Plan';
        } else {
          this.product = frequency_value + ' Months Plan';
        }
        //console.log(this.product);
        this.sku = 'G' + this.newFormData.class_id + '_CBSE_' + frequency_value + 'M'
        //console.log(this.sku);
      }

    }
    // console.log(this.newFormData)

    const requestData = {
      subscription: {
        user_id: this.newFormData.user_id,
        email: this.newFormData.email,
        firstName: this.newFormData.firstName,
        subscription_id: this.newFormData.subscription_id,
        school_id: this.newFormData.school_id,
        class_id: this.newFormData.class_id,
        amount: this.newFormData.amount,
        discount_amt: this.newFormData.discount_amt,
        paid_amount: this.newFormData.checkout_amount,
       // gst_amount: 3240,
        freq_type_id: this.newFormData.freq_type_id,
        frequency_value: this.frequency_type_value,
        payment_type: this.newFormData.payment_type,
        payment_response_id: this.newFormData.payment_type,
        status: 1
      },
      invoice: {
        firstName: this.newFormData.firstName,
        lastName: this.newFormData.lastName,
        address: this.newFormData.address,
        city: this.newFormData.city,
        state: this.newFormData.state,
        country_id: this.newFormData.country_id,
        pincode: this.newFormData.pincode,
        contactNumber: this.newFormData.contactNumber,
        price: this.newFormData.amount,
        quantity: 1,
        product: this.product,
        sku: this.sku,
        created_by: this.admin_id,
        //gst_no: '',
        state_id: this.newFormData.state_id,
        received_by: this.newFormData.received_by,
        cheque_receipt_no: this.newFormData.cheque_receipt_no,
        // no_of_installment: this.newFormData.no_of_installment,
        // emi_amount: ,
        // down_payment: 0,
        // down_payment_type: ''
      }
    };
    
    // console.log(requestData);

     this.subscriptionService.subPayment(requestData).subscribe((dataInvoice: any) => {
      //console.log(dataInvoice);
      if (dataInvoice.status == true) {
        let obj = {}
        this.loading = false;
        this.disableButton = false;
        this.subscriptionService.setSubPaymentFromData(obj)
        this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');
        // this.router.navigate(['/user']);
        this.router.navigate(['/invoiceUser', this.user_id]);
      } else {
        this.loading = false;
        this.disableButton = false;
        this.errorMessage = dataInvoice.message;
      }
    },
      (error) => {
        this.loading = false;
        this.disableButton = false;
        this.errorMessage = 'An error occurred.';
        console.error('An error occurred:', error);
      }
    )
  
  }


  payWithRazor() {
    // console.log(this.Subscription)

    let options: any = {
      "key": this.razorKey,
      "amount": (this.newFormData.checkout_amount * 100),
      "name": "Nifsoft",
      "description": "Nifsoft Classroom",
      "image": "./assets/images/company_logo.png",
      "modal": {
        "escape": true
      },
      "prefill": {
        // "contact": this.currentUser.adminUser[0].contactNumber,
        // "email": this.currentUser.adminUser[0].email,
        // "method": 'card',
        //   'card[number]': this.finalObj.cardDetail.cardNumber,
        //   'card[expiry]': this.finalObj.cardDetail.cardExpDate,
        //   'card[cvv]': this.finalObj.cardDetail.cardCvv
      },
      "notes": {
        // "address": this.finalObj.addressDetail.address + ', ' + this.finalObj.addressDetail.landmark + ', ' + this.finalObj.addressDetail.city + ', ' + this.finalObj.addressDetail.state + '-' + this.finalObj.addressDetail.pincode
      },
      "theme": {
        "color": "#6fbc29"
      }
    };
    options.handler = ((response) => {
      // options['payment_response_id'] = response.razorpay_payment_id;

      this.createSubscription(response.razorpay_payment_id);

      // this.paymentService.payWithRazor({cart: this.finalObj, payment: options});
    });
    options.modal.ondismiss = (() => {
      // this.loginService.SetLoader = false;
      // console.log("dismiss");
      // this.createSubscription("123");
    });
    let rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  payWithDoku() {
    let invoice_no = "INV-"+Date.now();
    if (this.frequencyTypeList && this.classList) {
      let freq_type_id = this.newFormData.freq_type_id;
      const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

      if (selectedDataProd) {
        const { frequency, frequency_value } = selectedDataProd;
        if (frequency_value === 1) {
          this.product = frequency_value + ' Month Plan';
        } else {
          this.product = frequency_value + ' Months Plan';
        }
      }

    }
    /*
    let sigObj = {
      amount: parseInt(this.newFormData.checkout_amount),
      invoice_no: invoice_no,
      product: this.product,
      price: parseInt(this.newFormData.checkout_amount),
      quantity: 1,
      user_id: parseInt(this.newFormData.user_id),
      customer_name: this.newFormData.firstName+' '+this.newFormData.lastName,
      email: this.newFormData.email,
      phone: this.newFormData.contactNumber,
      address: this.newFormData.address+' '+this.newFormData.city+' '+this.newFormData.state+' '+this.newFormData.pincode,
      country: "ID"
    };
    */
    
    //console.log('sigObj',sigObj);
    this.newFormData.invoice_number = invoice_no;
    this.newFormData.product = this.product;
    //localStorage.setItem('indoPayData', JSON.stringify(this.newFormData));
   // console.log('newformdata',this.newFormData);
    this.subscriptionService.getSignature(this.newFormData).subscribe((data: any) => {
     // console.log('getSignature',data);
      if(data.message[0] === 'SUCCESS'){
        let payment_url = data.response.payment.url;
        new this.winRef.nativeWindow.loadJokulCheckout(payment_url);
  
      } else {
        this.loading = false;
      this.disableButton = false;
      this.errorMessage = data.message;
       console.log('An error occurred:', data);
      }
      
      //this.newFormData.invoice_no = invoice_no;
      //this.newFormData.product = product;
      //let obj = Object.assign({}, this.newFormData.value);
      //this.subscriptionService.setSubPaymentFromData(obj);
      //this.router.navigate(['/indoPayResp']);

      
      //this.createSubscription('');
      //console.log('resp',resp);
    }, (error) => {
      this.loading = false;
      this.disableButton = false;
      this.errorMessage = 'An error occurred.';
      console.error('An error occurred:', error);
    });


    //console.log('sandbox_data',this.sandbox_data);
    
  }

  payDownPayment() {
    // console.log(this.Subscription)

    let options: any = {
      "key": this.razorKey,
      "amount": (this.newFormData.down_payment * 100),
      "name": "Nifsoft",
      "description": "Nifsoft Classroom",
      "image": "./assets/images/company_logo.png",
      "modal": {
        "escape": true
      },
      "prefill": {
        // "contact": this.currentUser.adminUser[0].contactNumber,
        // "email": this.currentUser.adminUser[0].email,
        // "method": 'card',
        //   'card[number]': this.finalObj.cardDetail.cardNumber,
        //   'card[expiry]': this.finalObj.cardDetail.cardExpDate,
        //   'card[cvv]': this.finalObj.cardDetail.cardCvv
      },
      "notes": {
        // "address": this.finalObj.addressDetail.address + ', ' + this.finalObj.addressDetail.landmark + ', ' + this.finalObj.addressDetail.city + ', ' + this.finalObj.addressDetail.state + '-' + this.finalObj.addressDetail.pincode
      },
      "theme": {
        "color": "#6fbc29"
      }
    };
    options.handler = ((response) => {
      // options['payment_response_id'] = response.razorpay_payment_id;

      this.createSubscription(response.razorpay_payment_id);

      // this.paymentService.payWithRazor({cart: this.finalObj, payment: options});
    });
    options.modal.ondismiss = (() => {
      // this.loginService.SetLoader = false;
      // console.log("dismiss");
      // this.createSubscription("123");
    });
    let rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }


  createSubscription(razorpay_payment_id) {
    // console.log('kd')
    this.zone.run(() => {
      this.loading = true;
      this.disableButton = true
    })
    if (this.frequencyTypeList) {
      let freq_type_id = this.newFormData.freq_type_id;
      //console.log(this.frequencyTypeList)
      const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

      if (selectedDataProd) {
        const { frequency, frequency_value } = selectedDataProd;
        this.frequency_type_value = frequency_value
        // console.log('Frequency:', this.frequency_type_value);
      }

    }
    // console.log(this.newFormData)
    let subscriptionObj = {
      email: this.newFormData.email,
      firstName: this.newFormData.firstName,
      user_id: this.newFormData.user_id,
      subscription_id: this.newFormData.subscription_id,
      school_id: this.newFormData.school_id,
      class_id: this.newFormData.class_id,
      amount: this.newFormData.amount,
      discount_amt: this.newFormData.discount_amt,
      paid_amount: this.newFormData.checkout_amount,
      gst_amount: this.newFormData.gst_amount,
      freq_type_id: this.newFormData.freq_type_id,
      frequency_value: this.frequency_type_value,
      payment_type: this.newFormData.payment_type,
      payment_response_id: razorpay_payment_id,
      status: 1,
    }
    //console.log(subscriptionObj);
    this.subscriptionService.createSubscription(subscriptionObj).subscribe((data: any) => {
      // console.log(data)
      if (data.status) {
        if (data.data.subscription.subscriptionId) {
          let tax_amount = this.newFormData.checkout_amount - this.newFormData.total_amount;
          // console.log('Tax:'+tax_amount)
          if (this.frequencyTypeList && this.classList) {
            let freq_type_id = this.newFormData.freq_type_id;
            //console.log(this.frequencyTypeList)
            const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

            if (selectedDataProd) {
              const { frequency, frequency_value } = selectedDataProd;
              // Patch the form values with the matched values in data
              //console.log('Frequency:', frequency);
              //  console.log('Frequency:', frequency_value);
              if (frequency_value === 1) {
                this.product = frequency_value + ' Month Plan';
              } else {
                this.product = frequency_value + ' Months Plan';
              }
              //console.log(this.product);
              this.sku = 'G' + this.newFormData.class_id + '_CBSE_' + frequency_value + 'M'
              //console.log(this.sku);
            }

          }
          let liferaySubObj = {
            order_id: data.data.subscription.subscriptionId,
            product: this.product,
            class_id: this.newFormData.class_id,
            email: this.newFormData.email,
            start_date: data.data.subscription.start_date,
            end_date: data.data.subscription.end_date,
          }

          //console.log(liferaySubObj)

          //Code when class_id is not 6
          if (this.newFormData.class_id != 6) {
            //console.log(liferaySubObj)
            this.subscriptionService.createSubscriptionLiferay(liferaySubObj).subscribe((dataLiferay: any) => {
              //console.log(dataLiferay);
              if (dataLiferay.status == true) {

                let invoiceObj = {
                  user_id: this.newFormData.user_id,
                  order_id: data.data.subscription.subscriptionId,
                  school_id: this.newFormData.school_id,
                  class_id: this.newFormData.class_id,
                  firstName: this.newFormData.firstName,
                  lastName: this.newFormData.lastName,
                  address: this.newFormData.address,
                  city: this.newFormData.city,
                  state: this.newFormData.state,
                  country_id: this.newFormData.country_id,
                  pincode: this.newFormData.pincode,
                  email: this.newFormData.email,
                  contactNumber: this.newFormData.contactNumber,
                  payment_type: this.newFormData.payment_type,
                  price: this.newFormData.amount,
                  discount_amount: this.newFormData.discount_amt,
                  tax_amount: tax_amount,
                  quantity: 1,
                  total_amount: this.newFormData.checkout_amount,
                  payment_response_id: razorpay_payment_id,
                  received_by: this.newFormData.received_by,
                  product: this.product,
                  sku: this.sku,
                  created_by: this.admin_id,
                  gst_no: this.newFormData.gst_no,
                  state_id: this.newFormData.state_id,
                  emi_amount: this.newFormData.emi_amount,
                  no_of_installment: this.newFormData.no_of_installment,
                  down_payment: this.newFormData.down_payment,
                  down_payment_type: this.newFormData.down_payment_type

                }
               // console.log(invoiceObj);
                this.subscriptionService.addInvoice(invoiceObj).subscribe((dataInvoice: any) => {
                  // console.log(data);
                  if (dataInvoice.status == true) {
                    let obj = {}
                    this.subscriptionService.setSubPaymentFromData(obj)
                    // this.router.navigate(['/user']);
                    this.zone.run(() => {
                      this.loading = false;
                      this.disableButton = false
                    })
                    this.zone.run(() => {
                      this.router.navigate(['/invoiceUser', this.user_id]);
                      this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');
                    });
                    // this.router.navigate(['/invoiceUser', this.user_id]);
                    //this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');

                  } else {
                    this.zone.run(() => {
                      this.loading = false;
                      this.disableButton = true
                      this.errorMessage = dataInvoice.message;
                      this.errorMessageResponseId = razorpay_payment_id;
                    })

                  }
                },
                  (error: any) => {
                    this.zone.run(() => {
                      this.loading = false;
                      this.disableButton = true;
                      this.errorMessage = "An error occurred. " + error;
                      this.errorMessageResponseId = razorpay_payment_id;
                    });
                  })


              } else {
                this.zone.run(() => {
                  this.loading = false;
                  this.disableButton = true;
                  this.errorMessage = dataLiferay.message;
                  this.errorMessageResponseId = razorpay_payment_id;
                })
              }
            },
              (error: any) => {
                this.zone.run(() => {
                  this.loading = false;
                  this.disableButton = true;
                  this.errorMessage = "An error occurred. " + error;
                  this.errorMessageResponseId = razorpay_payment_id;
                });
              })

          } else {

            let invoiceObj = {
              user_id: this.newFormData.user_id,
              order_id: data.data.subscription.subscriptionId,
              school_id: this.newFormData.school_id,
              class_id: this.newFormData.class_id,
              firstName: this.newFormData.firstName,
              lastName: this.newFormData.lastName,
              address: this.newFormData.address,
              city: this.newFormData.city,
              state: this.newFormData.state,
              country_id: this.newFormData.country_id,
              pincode: this.newFormData.pincode,
              email: this.newFormData.email,
              contactNumber: this.newFormData.contactNumber,
              payment_type: this.newFormData.payment_type,
              price: this.newFormData.amount,
              discount_amount: this.newFormData.discount_amt,
              tax_amount: tax_amount,
              quantity: 1,
              total_amount: this.newFormData.checkout_amount,
              payment_response_id: razorpay_payment_id,
              received_by: this.newFormData.received_by,
              product: this.product,
              sku: this.sku,
              created_by: this.admin_id,
              gst_no: this.newFormData.gst_no,
              state_id: this.newFormData.state_id,
              emi_amount: this.newFormData.emi_amount,
              no_of_installment: this.newFormData.no_of_installment,
              down_payment: this.newFormData.down_payment,
              down_payment_type: this.newFormData.down_payment_type
            }
           // console.log(invoiceObj);
            this.subscriptionService.addInvoice(invoiceObj).subscribe((dataInvoice: any) => {
              //  console.log(dataInvoice);
              if (dataInvoice.status == true) {
                let obj = {}
                this.subscriptionService.setSubPaymentFromData(obj)
                // this.router.navigate(['/user']);
                this.zone.run(() => {
                  this.zone.run(() => {
                    this.loading = false;
                    this.disableButton = false
                  })
                  this.router.navigate(['/invoiceUser', this.user_id]);
                  this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');
                });
                // this.router.navigate(['/invoiceUser', this.user_id]);
                //this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');

              } else {
                this.zone.run(() => {
                  this.loading = false;
                  this.disableButton = true;
                  this.errorMessage = dataInvoice.message;
                  this.errorMessageResponseId = razorpay_payment_id;
                })
              }
            },
              (error: any) => {
                this.zone.run(() => {
                  this.loading = false;
                  this.disableButton = true;
                  this.errorMessage = "An error occurred. " + error;
                  this.errorMessageResponseId = razorpay_payment_id;
                });
              })

          }
        } else {
          this.zone.run(() => {
            this.loading = false;
            this.disableButton = true;
            this.errorMessage = 'Server Error';
            this.errorMessageResponseId = razorpay_payment_id;
          })
        }
      } else {
        this.zone.run(() => {
          this.loading = false;
          this.disableButton = true;
          this.errorMessage = 'Server Error';
          this.errorMessageResponseId = razorpay_payment_id;
        })
      }
    },
      (error: any) => {
        this.zone.run(() => {
          this.loading = false;
          this.disableButton = true;
          this.errorMessage = "An error occurred. " + error;
          this.errorMessageResponseId = razorpay_payment_id
        });
      }
    );

  }


  subscribeBajajEMI() {
    // console.log('Bajaj Finance')
    this.loading = true;
    this.disableButton = true;
    let obj = {};
    obj = Object.assign({}, this.newFormData);
    // console.log(obj)

    if (this.frequencyTypeList) {
      let freq_type_id = this.newFormData.freq_type_id;
      //console.log(this.frequencyTypeList)
      const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

      if (selectedDataProd) {
        const { frequency, frequency_value } = selectedDataProd;
        this.frequency_type_value = frequency_value
        // console.log('Frequency:', this.frequency_type_value);
      }

    }
    // console.log(this.newFormData)
    let subscriptionObj = {
      email: this.newFormData.email,
      firstName: this.newFormData.firstName,
      user_id: this.newFormData.user_id,
      subscription_id: this.newFormData.subscription_id,
      school_id: this.newFormData.school_id,
      class_id: this.newFormData.class_id,
      amount: this.newFormData.amount,
      discount_amt: this.newFormData.discount_amt,
      paid_amount: this.newFormData.checkout_amount,
      gst_amount: this.newFormData.gst_amount,
      freq_type_id: this.newFormData.freq_type_id,
      frequency_value: this.frequency_type_value,
      payment_type: this.newFormData.payment_type,
      payment_response_id: this.newFormData.payment_type,
      status: 1,
    }
    //console.log(subscriptionObj);
    this.subscriptionService.createSubscription(subscriptionObj).subscribe((data: any) => {
      //console.log(data)
      if (data.status) {
        if (data.data.subscription.subscriptionId) {
          let tax_amount = this.newFormData.checkout_amount - this.newFormData.total_amount;
          // console.log('Tax:'+tax_amount)
          if (this.frequencyTypeList && this.classList) {
            let freq_type_id = this.newFormData.freq_type_id;
            //console.log(this.frequencyTypeList)
            const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

            if (selectedDataProd) {
              const { frequency, frequency_value } = selectedDataProd;
              // Patch the form values with the matched values in data
              //console.log('Frequency:', frequency);
              //  console.log('Frequency:', frequency_value);
              if (frequency_value === 1) {
                this.product = frequency_value + ' Month Plan';
              } else {
                this.product = frequency_value + ' Months Plan';
              }
              //console.log(this.product);
              this.sku = 'G' + this.newFormData.class_id + '_CBSE_' + frequency_value + 'M'
              //console.log(this.sku);
            }

          }
          let liferaySubObj = {
            order_id: data.data.subscription.subscriptionId,
            product: this.product,
            class_id: this.newFormData.class_id,
            email: this.newFormData.email,
            start_date: data.data.subscription.start_date,
            end_date: data.data.subscription.end_date,
          }
          // code when class_id is not 6
          if (this.newFormData.class_id != 6) {
            // console.log(liferaySubObj)
            this.subscriptionService.createSubscriptionLiferay(liferaySubObj).subscribe((dataLiferay: any) => {
              // console.log(dataLiferay);
              if (dataLiferay.status == true) {
                let invoiceObj = {
                  user_id: this.newFormData.user_id,
                  order_id: data.data.subscription.subscriptionId,
                  school_id: this.newFormData.school_id,
                  class_id: this.newFormData.class_id,
                  firstName: this.newFormData.firstName,
                  lastName: this.newFormData.lastName,
                  address: this.newFormData.address,
                  city: this.newFormData.city,
                  state: this.newFormData.state,
                  country_id: this.newFormData.country_id,
                  pincode: this.newFormData.pincode,
                  email: this.newFormData.email,
                  contactNumber: this.newFormData.contactNumber,
                  payment_type: this.newFormData.payment_type,
                  price: this.newFormData.amount,
                  discount_amount: this.newFormData.discount_amt,
                  tax_amount: tax_amount,
                  quantity: 1,
                  total_amount: this.newFormData.checkout_amount,
                  emi_amount: this.newFormData.emi_amount,
                  no_of_installment: this.newFormData.no_of_installment,
                  down_payment: this.newFormData.down_payment,
                  product: this.product,
                  sku: this.sku,
                  created_by: this.admin_id,
                  gst_no: this.newFormData.gst_no,
                  state_id: this.newFormData.state_id,
                  received_by: this.newFormData.received_by,
                  cheque_receipt_no: this.newFormData.cheque_receipt_no,
                  down_payment_type: this.newFormData.down_payment_type

                }
                //console.log(invoiceObj);

                this.subscriptionService.addInvoice(invoiceObj).subscribe((dataInvoice: any) => {
                  // console.log(data);
                  if (dataInvoice.status == true) {
                    let obj = {}
                    this.loading = false;
                    this.disableButton = false;
                    this.subscriptionService.setSubPaymentFromData(obj)
                    this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');
                    // this.router.navigate(['/user']);
                    this.router.navigate(['/invoiceUser', this.user_id]);
                  } else {
                    this.loading = false;
                    this.disableButton = false;
                    this.errorMessage = dataInvoice.message;
                  }
                }, (error) => {
                  this.loading = false;
                  this.disableButton = false;
                  this.errorMessage = 'An error occurred.';
                  console.error('An error occurred:', error);
                })

              } else {
                this.loading = false;
                this.disableButton = false;
                this.errorMessage = dataLiferay.message;
              }
            }, (error) => {
              this.loading = false;
              this.disableButton = false;
              this.errorMessage = 'An error occurred.';
              console.error('An error occurred:', error);
            })
          } else {

            let invoiceObj = {
              user_id: this.newFormData.user_id,
              order_id: data.data.subscription.subscriptionId,
              school_id: this.newFormData.school_id,
              class_id: this.newFormData.class_id,
              firstName: this.newFormData.firstName,
              lastName: this.newFormData.lastName,
              address: this.newFormData.address,
              city: this.newFormData.city,
              state: this.newFormData.state,
              country_id: this.newFormData.country_id,
              pincode: this.newFormData.pincode,
              email: this.newFormData.email,
              contactNumber: this.newFormData.contactNumber,
              payment_type: this.newFormData.payment_type,
              price: this.newFormData.amount,
              discount_amount: this.newFormData.discount_amt,
              tax_amount: tax_amount,
              quantity: 1,
              total_amount: this.newFormData.checkout_amount,
              emi_amount: this.newFormData.emi_amount,
              no_of_installment: this.newFormData.no_of_installment,
              down_payment: this.newFormData.down_payment,
              product: this.product,
              sku: this.sku,
              created_by: this.admin_id,
              gst_no: this.newFormData.gst_no,
              state_id: this.newFormData.state_id,
              received_by: this.newFormData.received_by,
              cheque_receipt_no: this.newFormData.cheque_receipt_no,
              down_payment_type: this.newFormData.down_payment_type
            }
           // console.log(invoiceObj);
            this.subscriptionService.addInvoice(invoiceObj).subscribe((dataInvoice: any) => {
              // console.log(data);
              if (dataInvoice.status == true) {
                let obj = {}
                this.subscriptionService.setSubPaymentFromData(obj)
                this.loading = false;
                this.disableButton = false;
                this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');
                // this.router.navigate(['/user']);
                this.router.navigate(['/invoiceUser', this.user_id]);
              } else {
                this.loading = false;
                this.disableButton = false;
                this.errorMessage = dataInvoice.message;
              }
            },
              (error) => {
                this.loading = false;
                this.disableButton = false;
                this.errorMessage = 'An error occurred.';
                console.error('An error occurred:', error);
              })
          }
        } else {
          this.loading = false;
          this.disableButton = false;
          this.errorMessage = 'Server Error. Please check all fields & retry!';
        }
      } else {
        this.loading = false;
        this.disableButton = false;
        this.errorMessage = 'Server Error. Please check all fields & retry! '+data.message;
      }
    }, (error) => {
      this.loading = false;
      this.disableButton = false;
      this.errorMessage = 'An error occurred.';
      console.error('An error occurred:', error);
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }
}
