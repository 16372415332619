import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { merge, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import { OnlineScheduleLessonComponent } from '../online-schedule-lesson/online-schedule-lesson.component';
import { ShowOnlineScheduleService } from '../show-online-schedule.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() joinClass = new Subject();
  @Input() tableData;
  customFilters: any;
  dataSource: any;
  total_schedule: number;
  scheduleData = [];
  limit = 10;
  offset = 0;
  currentUser: any;
  userType: number;
  displayedColumns: string[] = ['Sno', 'class', 'subject', 'lesson', 'classLevel', 'classType',
    'startDate', 'endDate', 'day', 'join', 'id'];
  sortBy = '';
  sortDirection = '';
  userid: number;
  token: string;
  tokenStatus  =false;
  onlineClassUrl = environment.onlineClassUrl;
  userDataVal: any;
  userName: string;
  userTypeVal: any;
  schoolId: number;
  classId: number;
  sectionId: number;
  subjectId: number;
  constructor(
    private onlineScheduleService: ShowOnlineScheduleService,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    // console.log(this.tableData);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userType = this.currentUser.userData[0].user_type;
    this.userid = this.currentUser.id;
    this.token = this.currentUser.token;
    this.getSchedule();
  }

  getSchedule() {
    this.customFilters = this.tableData.customFilters
    this.customFilters = {
      scheduleType: this.tableData.scheduleType,
      start_date: this.customFilters.start_date,
      end_date: this.customFilters.end_date,
      school_id: this.customFilters.school_id,
      class_id: this.customFilters.class_id,
      section_id: this.customFilters.section_id,
      user_id: this.customFilters.user_id,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection
    }
    console.log(this.customFilters);
    this.onlineScheduleService.getSchedule(this.customFilters).subscribe((scheduleList: any) => {
      console.log(scheduleList);
      this.scheduleData = [];
      scheduleList.data.forEach(sl => {
        let date = new Date(sl.end_date);
        let hours = date.getHours();
        sl.ampm = hours >= 12 ? 'PM' : 'AM';
        this.scheduleData.push(sl);
      });
      // console.log(this.scheduleData)
      this.dataSource = new MatTableDataSource(this.scheduleData);
      this.dataSource.sort = this.sort;
      this.total_schedule = scheduleList.totalSchedule;
    })
  }

  lessonSchedule(schedule_id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      schedule_id: schedule_id,
    };
    this.dialog.open(OnlineScheduleLessonComponent, dialogConfig);
  }

  openClass(schedule){
    this.userType = this.currentUser.userData[0].user_type;
    this.userName = this.currentUser.userData[0].studentName;
    this.schoolId = schedule.school_id;
    this.classId = schedule.class_id;
    this.sectionId = schedule.section_id;
    this.subjectId = schedule.subject_id;
    this.userDataVal =  "user_id="+this.userid+"&user_type="+this.userType+"&school_id="+this.schoolId+"&class_id="+this.classId+"&section_id="+this.sectionId+"&subject_id="+this.subjectId+"&userName="+this.userName;
    this.userDataVal = btoa(this.userDataVal);
    // console.log(this.userDataVal);
    let paramsVal = {user_id: this.userid, token: this.token};
    // console.log(paramsVal);
    return this.authService.chkUserToken(paramsVal).subscribe(
      data =>{
        this.tokenStatus = data.status;
        if(!this.tokenStatus){
          this.openSnackBar("You've been logged in different Computer", 'Close');
          this.authService.logout();
          //return false;
        } else {
          window.open(this.onlineClassUrl+'?'+this.userDataVal,"Nifsoft Online Class",'height=650,width=700');
        }
        this.joinClass.next();
      }
    )    
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          this.sortBy = data.action;
          this.sortDirection = data.direction;
          this.getSchedule();
        })
      ).subscribe();
  }

}
